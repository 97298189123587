
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import StorageService from "@/core/services/StorageService";
import ChangePassword from "@/views/users/ChangePassword.vue";

interface ProfileDetails {
  id: number;
  avatar: string;
  name: string;
  surname: string;
  company: string;
  contactPhone: string;
  companySite: string;
  country: string;
  language: string;
  timezone: string;
  currency: string;
  communications: {
    email: boolean;
    phone: boolean;
  };
  allowMarketing: boolean;
}

export default defineComponent({
  name: "ProfileSettings",
  components: {
    ErrorMessage,
    Field,
    Form,
    ChangePassword,
  },
  props: {
    profile: Object,
  },
  setup(props) {
    const store = useStore();
    const btnProfileDetails = ref<HTMLElement | null>(null);
    const submitButton2 = ref<HTMLElement | null>(null);
    const submitButton3 = ref<HTMLElement | null>(null);
    const submitButton4 = ref<HTMLElement | null>(null);
    const submitButton5 = ref<HTMLElement | null>(null);
    const updateEmailButton = ref<HTMLElement | null>(null);

    const emailFormDisplay = ref(false);

    const profileDetailsValidator = Yup.object().shape({
      fname: Yup.string().required().label("First name"),
    });

    const changeEmail = Yup.object().shape({
      emailaddress: Yup.string().required().email().label("Email"),
      confirmemailpassword: Yup.string().required().label("Password"),
    });

    const profileDetails = ref<ProfileDetails>({
      id: store.getters.currentUser.id,
      avatar: store.getters.currentUser.avatar || "media/defaults/avatar.png",
      name: store.getters.currentUser.name,
      surname: "",
      company: "THE CLOUD GROUP",
      contactPhone: "044 3276 454 935",
      companySite: "keenthemes.com",
      country: "MY",
      language: "msa",
      timezone: "Kuala Lumpur",
      currency: "USD",
      communications: {
        email: false,
        phone: false,
      },
      allowMarketing: false,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let avatar: any;

    const saveProfileDetails = () => {
      if (btnProfileDetails.value) {
        // Activate indicator
        console.clear();
        btnProfileDetails.value.setAttribute("data-kt-indicator", "on");
        const formData = new FormData();
        formData.append("name", profileDetails.value.name);
        formData.append("avatar", avatar);

        ApiService.postAttach(
          "/api/users/update/" + profileDetails.value.id,
          formData
        ).then(({ data }) => {
          StorageService.saveProfile(data);
          store.commit("setUser", data);
        });

        setTimeout(() => {
          btnProfileDetails.value?.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    };

    const previewAvatar = (event) => {
      const [file] = event.target.files;
      if (file) {
        profileDetails.value.avatar = URL.createObjectURL(file);
        avatar = event.target.files[0];
      }
    };

    const saveChanges2 = () => {
      if (submitButton2.value) {
        // Activate indicator
        submitButton2.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton2.value?.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    };

    const saveChanges3 = () => {
      if (submitButton3.value) {
        // Activate indicator
        submitButton3.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton3.value?.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    };

    const saveChanges4 = () => {
      if (submitButton4.value) {
        // Activate indicator
        submitButton4.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton4.value?.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    };

    const deactivateAccount = () => {
      if (submitButton5.value) {
        // Activate indicator
        submitButton5.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton5.value?.removeAttribute("data-kt-indicator");

          Swal.fire({
            text: "Email is successfully changed!",
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          }).then(() => {
            emailFormDisplay.value = false;
          });
        }, 2000);
      }
    };

    const updateEmail = () => {
      console.log(updateEmailButton.value);

      if (updateEmailButton.value) {
        // Activate indicator
        updateEmailButton.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          updateEmailButton.value?.removeAttribute("data-kt-indicator");

          emailFormDisplay.value = false;
        }, 2000);
      }
    };

    const removeImage = () => {
      profileDetails.value.avatar = "media/defaults/blank.png";
      avatar = "";
    };

    onMounted(() => {
      if (props.profile?.myself) {
        setCurrentPageBreadcrumbs("settings", ["profile"]);
      } else {
        setCurrentPageBreadcrumbs("settings", ["user", "iprofile"]);
      }
    });

    return {
      avatar,
      btnProfileDetails,
      submitButton2,
      submitButton3,
      submitButton4,
      submitButton5,
      previewAvatar,
      saveProfileDetails,
      saveChanges2,
      saveChanges3,
      saveChanges4,
      deactivateAccount,
      profileDetails,
      emailFormDisplay,
      removeImage,
      profileDetailsValidator,
      changeEmail,
      updateEmailButton,
      updateEmail,
    };
  },
});
